<template>
  <section class="misc-wrapper">
    <HeaderPayment />
      <b-container class="mt-2 mb-5 flex-fill container">
        <div v-for="detail in detailOrder" :key="detail.key">
          <div v-if="detailOrder">

            <b-row
              v-if="detail.orderdetailStatus != 2 && detail.itinerary.pax != ''"
              class="mt-3 justify-content-md-center"
            >
              <b-col class="col-md-7">
                <div class="customers Data">{{ detail.customer.name }}</div>
                <div v-if="validInfo(detail.itinerary)" class="alert alert-danger" role="alert">
                  Your transfer is null please contant with sales person 😞
                </div>
                <div v-else class="alert alert-success" role="alert">
                  Your transfer is correct 😊
                </div>
                <div>
                  <ValidationObserver ref="observer3">
                    <b-form
                      class="card"
                      v-if="detail.itinerary.pax != ''"
                    >
                      <b-row class="mt-3 centerTitle">
                        <b-col class="col-md-12">
                          <div class="titleTransfer">{{ detail.itemsold }}</div>
                        </b-col>
                        <b-col class="col-md-6">
                          <h5>{{ detail.itinerary.trip }}</h5>
                        </b-col>
                        <b-col class="col-md-6">
                          <h5>Arrival date {{ detail.operationdate }}</h5>
                        </b-col>
                      </b-row>

                      <b-row class="mt-3">
                        <b-col class="col-md-6">
                          <b-input-group slot-scope="" prepend="From">
                            <b-form-select
                              disabled
                              v-model="detail.itinerary.ifrom"
                              :options="destinations"
                            >
                            </b-form-select>
                          </b-input-group>
                          <br />
                          <b-input-group prepend="Pax">
                            <b-form-input
                              disabled
                              v-model="detail.itinerary.pax"
                              type="number"
                              placeholder="Enter pax"
                            >
                            </b-form-input>
                          </b-input-group>
                          <br />
                          <ValidationProvider rules="required" name="flight">
                            <b-input-group
                              slot-scope="{ valid, errors }"
                              prepend="Flight"
                            >
                              <b-form-input
                                type="text"
                                placeholder="Flight"
                                v-model="detail.itinerary.flight"
                                :state="errors[0] ? false : valid ? true : null"
                                disabled
                                locale="en"
                              >
                              </b-form-input>
                              <b-form-invalid-feedback>
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-input-group>
                          </ValidationProvider>
                          <br />
                          <ValidationProvider rules="required" name="pickup">
                            <b-input-group
                              slot-scope="{ valid, errors }"
                              prepend="Pickup"
                            >
                              <b-form-input
                                :id="'pickup' + detail.id"
                                type="time"
                                disabled
                                placeholder="Pickup"
                                v-model="detail.itinerary.pickup"
                                :state="errors[0] ? false : valid ? true : null"
                                locale="en"
                              >
                              </b-form-input>
                              <b-form-invalid-feedback>
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-input-group>
                          </ValidationProvider>
                        </b-col>

                        <b-col class="col-md-6">
                          <b-input-group prepend="To">
                            <b-form-select
                              disabled
                              v-model="detail.itinerary.ito"
                              :options="destinations"
                            />
                          </b-input-group>
                          <br />
                          <ValidationProvider rules="required" name="airline">
                            <b-input-group
                              slot-scope="{ valid, errors }"
                              prepend="Airline"
                            >
                              <b-form-input
                                list="my-list-id"
                                placeholder="Add Airline"
                                :state="errors[0] ? false : valid ? true : null"
                                disabled
                                v-model="detail.itinerary.airline"
                                locale="en"
                              />
                              <datalist id="my-list-id">
                                <option
                                  v-for="aerolinea in aerolineas"
                                  :key="aerolinea.key"
                                >
                                  {{ aerolinea.text }}
                                </option>
                              </datalist>
                              <b-form-invalid-feedback>
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-input-group>
                          </ValidationProvider>
                          <br />

                          <ValidationProvider rules="required" name="flighttime">
                            <b-input-group
                              slot-scope="{ valid, errors }"
                              label="Fligth time"
                              prepend="Flight time"
                            >
                              <b-form-input
                                type="time"
                                @change="
                                  getDate(
                                    detail.itinerary.flighttime,
                                    detail.itinerary.pickup,
                                    detail.id,
                                    detail.itinerary,
                                    detail.itinerary.trip
                                  )
                                "
                                disabled
                                v-model="detail.itinerary.flighttime"
                                :state="errors[0] ? false : valid ? true : null"
                                locale="en"
                              >
                              </b-form-input>
                              <b-form-invalid-feedback>
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-input-group>
                          </ValidationProvider>
                        </b-col>

                        <b-col>
                          <div>
                            <b-button
                              class="float-right mr-2"
                              variant="success"
                              :href="couponLink + detail.order + '/' + detail.productID + '/' + detail.id + '/transfer/'"
                              target="_blank"
                              :disabled="validInfo(detail.itinerary)"
                            >
                              <b-icon-file-earmark-arrow-down/>
                              Coupon
                            </b-button>
                          </div>
                        </b-col>
                      </b-row>
                      <br />
                    </b-form>
                  </ValidationObserver>
                </div>
              </b-col>

          </b-row>
          <div v-else>
            <div
              v-if="detail.itinerary.pax != ''"
              class="alert alert-danger"
              role="alert"
            >
              Your transfer is canceled
            </div>
            <div v-if="detail.itinerary.pax == ''">
              <div class="w-100 text-center">
                <h2 class="mb-1">Your transfer is not valid ❌</h2>
                <img
                  src="/img/error.7cce96d0.svg"
                  alt="Error page"
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          </div>
          <div v-else>
            <div class="w-100 text-center">
              <h2 class="mb-1">No information found ❌</h2>
              <img
                src="/img/error.7cce96d0.svg"
                alt="Error page"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </b-container>
    <FooterPayment/>
  </section>
</template>

<script>
import HeaderPayment from "@/modules/shop/components/HeaderPayment";
import FooterPayment from "@/modules/shop/components/FooterPayment";
import { mapActions, mapState, mapMutations } from "vuex";

import { optionsTrip } from "@/data/data";
import { transfersIsValidNull } from "@/helpers/helpers";
export default {
  async mounted() {
    this.setNullDataOrder();
    if (this.order) {
      await this.getDataOrder();
      // await this.fetchDestinations();
      // await this.fetchAerolineas();
      await this.getAirlinesAndDestinations();
    }
  },
  data() {
    return {
      couponLink: process.env.VUE_APP_ORDER_DETAIL_COUPON_PDF,
      order: this.$route.query.order,
      optionsTrip,
      transfersIsValidNull: false,
      onchangeTranfers: false,
      time: "00:00",
    };
  },
  components: {
    HeaderPayment,
    FooterPayment,
  },
  computed: {
    ...mapState("start", ["destinations"]),
    ...mapState("start", ["aerolineas"]),
    ...mapState("mySales", ["detailOrder"]),
    ...mapState("operation", ["updateStatus"]),
  },
  methods: {
    ...mapMutations("shop", ["setDataOrder"]),
    ...mapMutations("operation", ["updateInfoValid"]),
    ...mapMutations("mySales", ["setPickupDetailOrder"]),

    ...mapActions("mySales", [
      "fetchTransferDetail",
      "getAirlinesAndDestinations",
    ]),
    ...mapActions("start", ["fetchDestinations"]),
    ...mapActions("start", ["fetchAerolineas"]),
    async getDataOrder() {
      const payload = { idOrder: this.order };
      await this.fetchTransferDetail(payload);
    },
    setNullDataOrder() {
      this.setDataOrder(null);
    },
    validInfo(data) {
      return transfersIsValidNull(data)
    },
    getDate(flightTime, pickupTime, idOrder, itinerario, type) {
      if (type == "Departure") {
        const timepo = flightTime.split(":");
        var hora = 4;
        var minutoSumar = 15;
        var minutos_final = parseInt(timepo[1]) + parseInt(minutoSumar);
        var sumaHora;
        if (minutos_final < 60) {
          minutoSumar = minutos_final;
        } else {
          minutos_final = parseInt(minutos_final) / 60;
          minutos_final = minutos_final.toString();
          minutos_final = minutos_final.split(".");
          hora = parseInt(minutos_final[0]) + parseInt(hora);
          minutoSumar = (parseInt(timepo[1]) + parseInt(minutoSumar)) % 60;
        }
        const hora_final = (parseInt(timepo[0]) + parseInt(hora)) % 24;

        if (hora_final < 10) {
          sumaHora = "0" + hora_final;
        } else {
          sumaHora = hora_final;
        }
        if (minutoSumar < 10) {
          minutos_final = "0" + minutoSumar;
        } else {
          minutos_final = minutoSumar;
        }

        document.getElementById("pickup" + idOrder).value =
          sumaHora + ":" + minutos_final;
        itinerario.idOrder = idOrder;
        itinerario.pickup = sumaHora + ":" + minutos_final;
        this.setPickupDetailOrder(itinerario);
      } else {
        const timepo = flightTime.split(":");
        const hora = 0;
        const hora_final = (parseInt(timepo[0]) + parseInt(hora)) % 24;
        sumaHora;
        if (hora_final < 10) {
          sumaHora = "0" + hora_final;
        } else {
          sumaHora = hora_final;
        }
        document.getElementById("pickup" + idOrder).value =
          sumaHora + ":" + timepo[1];
        itinerario.idOrder = idOrder;
        itinerario.pickup = sumaHora + ":" + timepo[1];
        this.setPickupDetailOrder(itinerario);
      }
    },
  },
};
</script>

<style>
form.card {
  border-radius: 0;
  padding: 16px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  margin: 0 0 39px 0;
  border: 0;
}

.custom-select {
  border: 0;
  border-bottom: 1px solid #cccc;
}

.titleTransfer {
  text-align: center;
  font-size: 17px;
  margin: 14px 0 26px 0;
}

.row.mt-3.justify-content-md-center.centerTitle {
  text-align: center;
  border-bottom: 1px solid #999;
  margin: 0 0 32px 0;
  padding: 0 0 14px 0;
}
.customers.Data {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  padding: 0 0 13px 0;
}
.row.mt-3.centerTitle {
  text-align: center;
  border-bottom: 1px solid #999;
  padding: 0 0 7px 0;
}

.alert.alert-danger {
  padding: 19px 12px 19px 12px;
}

.container {
  padding-bottom: 110px;
}

.misc-wrapper {
  position: relative;
  height: 100%;
  display: contents;
}
#app > div {
  display: inline-table;
}
.alert {
  transition: all 0.25s ease;
  padding: 12px 12px 11px 13px;
  margin: 0 0 0px 0;
  font-size: 16px;
  text-align: center;
}
</style>
<style scoped src="@/assets/css/success.css"></style>
